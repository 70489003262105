import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import { DotMenuIcon, TrashIcon, EyeIcon2 } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import ApprovalRequests from "../News/ApprovalRequests";
//import UnderwayOrderTable from "./subtabs/underwayOrderTable";
import UnderwayOrderTable from "../Orders/subtabs/underwayOrderTable";
import AdsTable from "../News/AdsTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import AppLoader from "../../components/Loader/AppLoader";
import { useSelector } from "react-redux";
// import InfoOrderTable from "./OrderDetails";
//mport CompleteOrderTable from "./subtabs/CompleteOrder";
import CompleteOrderTable from "../Orders/subtabs/CompleteOrder";
//import ReturnOrderTable from "./subtabs/ReturnOrder";
import ReturnOrderTable from "../Orders/subtabs/ReturnOrder";

import DeliveryBoysTable from "./subtabs/DeliveryBoys";
import Merchants from "./subtabs/Merchants";
import { useGetNonVerifiedUsersQuery, useGetUsersQuery } from "../../store/services/userService";

const UserRequests = () => {
  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));

  const {
    data: userData,
    isLoading,
    isSuccess,
    isError,
  } = useGetNonVerifiedUsersQuery();



  const [merchants, setMerchants] = useState([]);
  const [riders, setRiders] = useState([]);
  const tabs = [
    {
      label: words["Delivery Boys"],
      value: "deliveryboys",
    },
    {
      label: words["Merchants"],
      value: "merchants",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (userData?.status == 1) {
      setMerchants(userData.merchants);
      setRiders(userData.riders);
    }
  }, [userData]);

  return (
    <>
      {loading && <AppLoader />}

      <Layout pageTitle={words["Users"]}>
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            {isAdmin?.type == "admin" ? (
              <div className="flex flex-col gap-5 mt-6">
                <div className="flex w-full border-b-[1px] border-b-themeColor border-transparent rounded ">
                  {tabs.map((item, index) => (
                    <button
                      key={index}
                      className={`btn font-normal rounded ${activeTab === item.value
                        ? "bg-[#2EBFB9] text-white"
                        : "bg-transparent text-[#2EBFB9]"
                        }`}
                      onClick={(e) => setActiveTab(item.value)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
                {activeTab === "deliveryboys" && (
                  <DeliveryBoysTable
                    type="requests"
                    data={riders} />
                )}
                {activeTab === "merchants" && (
                  <Merchants
                    type="requests"
                    loadingState={(val) => {
                      setLoading(val);
                    }}
                    refreshData={""}
                    data={merchants}

                  />
                )}
              </div>
            ) : (
              <UnderwayOrderTable refreshData={""} data={""} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserRequests;
