import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography } from "antd";

import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import {
  DotMenuIcon,
  NotesIcon,
  IncomeIcon,
  UsersIcon,
  ChartIcon,
  TrashIcon,
  EyeIcon2,
} from "../../assets/Icons";
import NewsDetailsModel from "../../components/NewsDetailsModel";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import {
  useApproveAdMutation,
  useGetAdsQuery,
  useRejectAdMutation,
} from "../../store/services/adsService";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";
import { Image } from "antd";
import { useGetOrdersQuery } from "../../store/services/orderService";

const Main = () => {
  // const words = useSelector((state)=>state.au)

  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    users: "",
    posts: "",
    ad: "",
    income: "",
  });
  const [latestOrders, setLatestOrders] = useState([]);


  const columns = [
    {
      title: words["Order ID"],
      dataIndex: "id",
      key: "title",
    },
    {
      title: words["Merchant Name"],
      dataIndex: "merchant_name",
      key: "merchantName",
    },
    {
      title: words["Customer  Name"],
      dataIndex: "cust_name",
      key: "cust_name",
    },

    {
      title: words["Date"],
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["From"],
      dataIndex: "merchant_location",
      key: "merchant_location",
    },
    {
      title: words["To"],
      dataIndex: "cust_location",
      key: "cust_location",
    },

    {
      title: words["Order Type"],
      dataIndex: "order_type",
      key: "order_type",
    },

    {
      title: words["Status"],
      dataIndex: "order_status",
      key: "order_status",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {capitalizeFirstLetter(record.order_status)}
        </div>
      )

    },

    {
      title: words["Delivery Boy"],
      key: "deliveryBoy",
      render: (_, record) => (
        <>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={IMAGE_URL + record.rider?.image}
              style={{ width: 30, height: 30, borderRadius: 5 }}
            />
            {record.rider?.username}
          </div>
        </>
      ),
    },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/order-details/" + record.id);
                  // alert('asd');
                  return;
                }

              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          {/* <Button> */}
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
          {/* </Button> */}
        </Dropdown>
      ),
    },
  ];



  const getDashboard = async () => {
    const onSuccess = (res) => {
      setLoading(false);
      setCounts({
        ...counts,
        users: res?.data.users_count,
        posts: res?.data.posts_count,
        ad: res?.data.ad_count,
        income: res?.data.income,
      });

      setLatestOrders(res.data.latest_orders);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.dashboard, true, onSuccess, onError);
  };



  useEffect(() => {
    getDashboard();
  }, []);


  return (
    <Layout>
      {loading ? (
        <div className="wrap wrapWidth flex flex-col items-center ">
          <Loader />
        </div>
      ) : (
        <div className="lading-page h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="home-grid w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 mb-8">
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#47A000]/20 h-16 w-16 rounded-2xl">
                  <UsersIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Merchant"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.users}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#0066A0]/20 h-16 w-16 rounded-2xl">
                  <UsersIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Delivery"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.posts}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#A0009A]/20 h-16 w-16 rounded-2xl">
                  {/* <div className="text-[#A0009A]"> */}
                  <ChartIcon />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Orders"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.ad}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#F21531]/20 h-16 w-16 rounded-2xl">
                  <div className="text-[#F21531]">
                    <IncomeIcon />
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Income"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.income}
                  </h2>
                </div>
              </div>
            </div>

            <div>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={latestOrders}
                columns={columns}
              />
            </div>


            {/* <div>
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={latestPost}
                columns={postsColumns}
              />
            </div> */}

            {/* <div className="flex flex-col gap-2 mb-8">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        {words["No"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Image"]}
                      </th>
                      <th className="px-2 py-2 text-left  w-56 text-sm text-black font-medium">
                        {words["Title"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Date"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Category"]}
                      </th>
                      
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Publisher"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Status"]}
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {latestPost?.map((item, index) => (
                      <TableRow item={item} index={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}

            {/* <div className="flex flex-col gap-2">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest orders"]}
              </h1>
              <div>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={ads?.data ?? []}
                  columns={columns}
                />
              </div>
            </div> */}
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NewsDetailsModel
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default Main;
