import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Icons } from "../../../assets/svgs/svg";
import { Input } from "antd";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { routes } from "../../../Api/routes";
import { toast } from "react-toastify";
import { postRequest } from "../../../Api";
import { useAddBalanceMutation } from "../../../store/services/userService";

export default function AddBalanceOpenModel({ id, loading = () => { } }) {
  const [open, setOpen] = React.useState(false);
  const words = useSelector((state) => state.authReducer.words);
  // LOADING ??
  const [addBalance, { isLoading }] = useAddBalanceMutation();
  // const {addBalance,isLoading} = useAddBalanceMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [amount, setAmount] = React.useState("");
  const handleAmountChange = (event) => setAmount(event.target.value);

  

  // const doAddBalance = async () => {


  //   const onSuccess = (res) => {
  //     loading(false);
  //     toast.success("Balance added successfully");
  //     setOpen(false);
  //   }

  //   const onError = (err) => {
  //     loading(false);
  //     toast.error(err.message);
  //     setOpen(false);
  //   }

  //   const body = {
  //     rider_id: id,
  //     balance: amount,
  //   };
  //   console.log(body);

  //   loading(true);
  //   await postRequest(body, routes.addBalance, true, onSuccess, onError);

  // }

  const doAddBalance = async () => {
    const body = {
      rider_id: id,
      balance: amount,
    };
    
    await addBalance(body);
  }

  React.useEffect(() => {
    if (isLoading) {
      loading(true);
    }
    if (!isLoading) {
      loading(false);
      // toast.success("Balance added successfully");
      setOpen(false);
    }
  }, [isLoading]);

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={handleOpen}>
        {/* <Icons.threeDot /> */}
        <div className="flex flex-1 justify-end">
          <button
            className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
            style={{ zIndex: 1 }}
          >
            <div className="flex items-center justify-center text-white font-normal text-2xl">
              +
            </div>
            <label className="text-white cursor-pointer">
              {words["Add"]}
            </label>
          </button>
        </div>


      </div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent>
          <Header>
            <CloseButton onClick={handleClose}>
              <Icons.Cross />
            </CloseButton>
            <Title>ADD BALANCE</Title>
          </Header>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              id="amount"
              type="number"
              style={{
                borderRadius: "55px",
                height: "60px",
                padding: "0 60px 0 20px",
                border: "2px solid #ccc",
                outline: "none",
                flex: "1",
              }}
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
            />
            <span
              style={{
                position: "absolute",
                right: "20px",
                height: "60px",
                display: "flex",
                alignItems: "center",
                color: "#999",
                pointerEvents: "none",
              }}
            >
              OMR
            </span>
          </div>

          <ActionButtons>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "10vh",
                width: "100vw",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "black",
                  color: "black",
                  borderRadius: "100px",
                  width: "120px",
                  height: "50px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={doAddBalance}
              >
                OK
              </Button>
            </Box>
          </ActionButtons>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 12px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 8px 16px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
    width: 484px; /* Set the width of the modal */
  `
);

const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Title = styled("h1")`
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
`;

const CloseButton = styled("button")`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: ${grey[500]};
  transition: color 0.3s;

  &:hover {
    color: ${grey[900]};
  }
`;

const Divider = styled("hr")`
  border: none;
  border-top: 1px solid ${grey[300]};
  margin: 16px 0;
`;

const Label = styled("label")`
  font-size: 14px;
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid ${grey[300]};
  padding: 8px;
  margin-bottom: 16px;
`;

const ActionButtons = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  text-transform: none;
`;
