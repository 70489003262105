import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const userService = createApi({
  reducerPath: "user",
  tagTypes: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = localStorage.getItem('admin-token')
      // reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {

    return {
      getUsers: builder.query({
        query: () => {
          return {
            url: "/api/admin/getUsers",
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),
      getNonVerifiedUsers: builder.query({
        query: () => {
          return {
            url: "/api/admin/getNonVerifiedUsers",
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),

      getRiderDetails: builder.query({
        query: (id) => {
          return {
            url: "/api/admin/getRiderDetails/" + id,
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),
      getMerchantDetails: builder.query({
        query: (id) => {
          return {
            url: "/api/admin/getMerchantDetails/" + id,
            method: "POST",
          };
        },
        providesTags: ["user"],
      }),


      addBalance: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/addRiderBalance",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),

      changeMerchantAuthStatus: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/changeMerchantAuthStatus",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),
      changeRiderAuthStatus: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/changeRiderAuthStatus",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),
      




    };
  },
});
export const { useAddStaffMutation, useGetRiderDetailsQuery, useGetMerchantDetailsQuery, useGetUsersQuery,useAddBalanceMutation, useChangeMerchantAuthStatusMutation,useChangeRiderAuthStatusMutation, useGetNonVerifiedUsersQuery } = userService;
export default userService;
